import Papa from 'papaparse';
export default {
	state:
	{
		totalGenderPeople: 0,
		totalOc: 0,
		totalIn: 0,
		chartDataGender: createChartDataStructure(),
		chartDataInostran: createChartDataStructure(),
		chartDataCity: createChartDataStructure(),
		chartDataAge: createChartDataStructure(),
		chartDataCountry: [],
		chartDataAnonim: createChartDataStructure(),
		chartDataOccupation: createChartDataStructure(),
		chartDataOtlichniki: createChartDataStructure(),
	},
	mutations:
	{
		SET_CHART_DATA(state, { type, data })
		{
			state[`chartData${type.charAt(0).toUpperCase() + type.slice(1)}`] = data;
		},
		SET_TOTALS(state, { type, data })
		{
			state[`total${type.charAt(0).toUpperCase() + type.slice(1)}`] = data;
		},
	},
	actions: {
		loadCSV({ commit }, { file, actionType }) {
			Papa.parse(file, {
				header: true,
				download: true,
				complete: (results) => {
					const csvData = results.data;
					this.dispatch('processData', {actionType, data: csvData}, commit);
				},
				error: (error) => {
					console.error(`Ошибка при парсинге CSV ${actionType.toLowerCase()}:`, error);
				},
			});
		},

		processData({ commit }, { actionType, data })
		{
			const fieldMappings =
			{
				gender: { name: 'gender', count: 'gender_count' },
				age: { name: 'age', count: 'age_count' },
				inostran: { name: 'inostran', count: 'inostran_count' },
				anonim: { name: 'anonim', count: 'anonim_count' },
				occupation: { name: 'occupation', count: 'occupation_count' },
				otlichniki: { name: 'otlichniki', count: 'otlichniki_count' },
				city: { name: 'city', count: 'city_count' },
				country: { name: 'country', count: 'country_count' },
			};

			const { name, count } = fieldMappings[actionType] || {};
			if (!name || !count)
			{
				console.error(`Недопустимый actionType: ${actionType}`);
				return;
			}
			const labels = data.map(row => row[name]);
			const counts = data.map(row => +row[count] || 0);

			if (actionType === 'gender' || actionType === 'inostran')
			{
				const total = counts.reduce((a, b) => a + b, 0);
				commit('SET_TOTALS', { type: actionType === 'gender' ? 'GenderPeople' : 'In', data: total });
			}
			else if (actionType === 'occupation')
			{
				const total = counts.reduce((a, b) => a + b, 0);
				commit('SET_TOTALS', { type: 'Oc', data: total });
			}
			const chartData =
			{
				labels,
				datasets: [{
					backgroundColor: actionType === 'gender' || actionType === 'occupation' ? 
					['#FF5733', '#89CFF0', '#52be80', '#f7dc6f', '#f39c12', '#bb8fce'] :
					['#52be80', '#FF5733', '#89CFF0', '#f7dc6f', '#f39c12', '#bb8fce'],
					data: counts,
				}],
			};


			commit('SET_CHART_DATA', { type: actionType, data: chartData });

			if (actionType === 'city')
			{
				const sortedData = data.sort((a, b) => +b.city_count - +a.city_count);
				commit('SET_CHART_DATA', { type: 'City', data: sortedData });
			}

			if (actionType === 'country')
			{
				commit('SET_CHART_DATA', { type: 'Country', data });
			}
		}
	}
}
function createChartDataStructure()
{
	return {
		labels: [],
		datasets: [{
			backgroundColor: [],
			data: []
		}],
	};
}
